<main class="main">
  <div class="content">
    <div class="container">
      <h2>You don't have permission to access {{ service }} service.
      </h2>
      <small>
        Please contact our team for assistance.
      </small>
      <div class="pill-group">
        <a class="pill" href="/" target=""> Back to Login
        </a>
      </div>
    </div>
  </div>
</main>
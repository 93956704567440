// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
    name: "testing",
    production: false,
    msalConfig: {
        auth: {
            clientId: 'a3e7da7a-4b56-4389-87ad-23dc4fa4a7ac',
            authority: 'https://login.microsoftonline.com/75991416-2415-467c-ba1d-a35f004a7ea5'
        }
    },
    apiConfig: {
        scopes: ['api://a3e7da7a-4b56-4389-87ad-23dc4fa4a7ac/access_via_approle_assignments'],
        uri: 'https://graph.microsoft.com/v1.0/me'
    }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.

import { HttpClient, HttpXhrBackend } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Component, OnInit } from '@angular/core';
import { MsalBroadcastService, MsalService } from '@azure/msal-angular';
import { AccountInfo, AuthenticationResult, EventMessage, EventType, InteractionStatus } from '@azure/msal-browser';
import { filter } from 'rxjs/operators';

type ProfileType = {
  givenName?: string,
  surname?: string,
  userPrincipalName?: string,
  mail?: string,
  id?: string
};

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {
  loginDisplay = false;
  profile: ProfileType | undefined;
  account: AccountInfo | undefined;
  hasSelect: boolean = false;
  hasOnboard: boolean = false;
  hasTest: boolean = false;
  hasFind: boolean = false;
  isProduction: boolean = environment.name == "production"

  testLinkUrl: string = "";
  selectLinkUrl: string = "";
  onboardLinkUrl: string = "";
  findLinkUrl: string = "";

  accessToken: string = "";
  refreshToken: string = "";
  environmentName = environment.name;

  constructor(
    private http: HttpClient,
    private authService: MsalService,
    private msalBroadcastService: MsalBroadcastService) { }

  ngOnInit() {
    this.msalBroadcastService.msalSubject$
      .pipe(
        filter((msg: EventMessage) => msg.eventType === EventType.LOGIN_SUCCESS),
      )
      .subscribe((result: EventMessage) => {
        const payload = result.payload as AuthenticationResult;
        this.authService.instance.setActiveAccount(payload.account);
        this.account = payload.account;
        this.SetButtonControl(payload.account.idTokenClaims?.roles)
        this.SetProductLinks()
      });

    this.msalBroadcastService.inProgress$
      .pipe(
        filter((status: InteractionStatus) => status === InteractionStatus.None)
      )
      .subscribe(() => {
        this.setLoginDisplay();
      })

    this.SetButtonControl(this.authService.instance.getActiveAccount()?.idTokenClaims?.roles)
    this.SetProductLinks()
  }
  // Tanes: Refactor URLs
  SetProductLinks() {
    const sessionKey = JSON.parse(sessionStorage.getItem(`msal.token.keys.${environment.msalConfig.auth.clientId}`) || "{}")
    this.accessToken = JSON.parse(sessionStorage.getItem(sessionKey.accessToken[0]) || "{}").secret || "";
    this.refreshToken = JSON.parse(sessionStorage.getItem(sessionKey.refreshToken) || "{}").secret || ""

    this.onboardLinkUrl = this.isProduction ? "https://onboard.yourexpats.nl/" : "https://staging.yourexpats.nl/";
    this.testLinkUrl = this.GetTestLinkUrl(this.accessToken, this.refreshToken);
    this.selectLinkUrl = this.isProduction ? "https://select.yourexpats.nl/" : "https://staging.select.yourexpats.nl/";
    this.findLinkUrl = this.isProduction ? "https://find.yourexpats.nl/" : "https://staging.find.yourexpats.nl/";
  }

  GetTestLinkUrl(access_token: string = "", refresh_token: string = ""): any {
    if (environment.name == "testing") {
      return `https://staging.test.yourexpats.nl/?access_token=${access_token}&refresh_token=${refresh_token}`
    }
    return `https://test.yourexpats.nl/?access_token=${access_token}&refresh_token=${refresh_token}`
  }

  setLoginDisplay() {
    this.loginDisplay = this.authService.instance.getAllAccounts().length > 0;
    if (this.loginDisplay === true) {
      this.getToken(environment.apiConfig.uri);
    }
  }

  getToken(url: string) {
    this.authService.acquireTokenSilent({
      scopes: ['user.read']
    }).subscribe((apiResponse) => {
      const apiToken = `Bearer ${apiResponse.accessToken}`;
      sessionStorage.setItem('graphtoken', apiToken)
      this.getProfile();
    })
  }

  getProfile() {
    const httpClient = new HttpClient(new HttpXhrBackend({
      build: () => new XMLHttpRequest()
    }));

    const token = sessionStorage.getItem('graphtoken') || "";
    httpClient.get(environment.apiConfig.uri, {
      headers: { Authorization: token }
    }).subscribe(apiData => {
      this.profile = apiData;
    });

  }

  SetButtonControl(roles: string[] | undefined) {
    if (roles == undefined) return;
    this.hasFind = roles.indexOf("login.find") > -1
    this.hasSelect = roles.indexOf("login.select") > -1
    this.hasOnboard = roles.indexOf("login.onboard") > -1
    this.hasTest = roles.indexOf("login.test") > -1
  }

  logout() {
    this.authService.logoutRedirect();
  }
}



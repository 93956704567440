<span *ngIf="loginDisplay">
    <div class="header">
        <a href="/"><img class="logo" src="assets/Logo.svg" /></a>
    </div>
    <div class="wrapper">
        <router-outlet></router-outlet>
    </div>

    <footer class="footer" [class.footer-red]="!isProduction">
        <div class="footer-content">
            <span>&copy; 2024 <a href="https://yourexpats.nl">YourExpats.</a> All rights reserved.</span>
            <!-- <br>
        <span>For support <a href="mailto:info@yourexpats.nl">{{ 'info@yourexpats.nl' }}</a> or call <a
                href="tel:+31702210711">+31 70 221 07 11</a></span> -->
        </div>
    </footer>
</span>
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-no-permission',
  templateUrl: './no-permission.component.html',
  styleUrl: './no-permission.component.css'
})
export class NoPermissionComponent {
  service: string;

  constructor(private route: ActivatedRoute) {
    this.service = "this service"
    this.route.queryParamMap.subscribe(p => {
      const service = p.get('service')
      if (service && ['onboard', 'select', 'test', 'find'].includes(service)) {
        this.service = `"${service}"`.toUpperCase()
      }
    })
  }


}

<main class="main" *ngIf="loginDisplay">
  <div class="content">
    <div class="left-side">
      <h1>Hello {{ profile?.givenName }}</h1>
      <div>
        <p *ngIf="!isProduction"><strong>Environment: </strong> {{environmentName}}</p>
        <p><strong>First Name: </strong> {{ profile?.givenName }}</p>
        <p><strong>Last Name: </strong> {{ profile?.surname }}</p>
        <p><strong>Email: </strong> {{ profile?.mail }}</p>
        <!-- <p><strong>Id: </strong> {{ profile?.id }}</p> -->
      </div>
    </div>
    <div class="divider" role="separator" aria-label="Divider"></div>
    <div class="right-side">
      <div class="pill-group">
        @for (item of [
        { title: 'Onboard - Your Expats', link: onboardLinkUrl, disabled: !hasOnboard },
        { title: 'Select - Your Expats', link: selectLinkUrl, disabled: !hasSelect },
        { title: 'Test - Your Expats', link: testLinkUrl, disabled: !hasTest },
        { title: 'Find - Your Expats', link: findLinkUrl, disabled: !hasFind }
        ]; track item.title) {
          <a [class]="(item.disabled ? 'disabled-pill' : 'pill') + ' pill'" [href]="item.disabled ? '#' : item.link"
          [target]="!item.disabled ? '_blank' : ''" rel="noopener">
        <span>{{ item.title }}</span>
        <svg xmlns="http://www.w3.org/2000/svg" height="14" viewBox="0 -960 960 960" width="14" fill="currentColor">
          <path
            d="M200-120q-33 0-56.5-23.5T120-200v-560q0-33 23.5-56.5T200-840h280v80H200v560h560v-280h80v280q0 33-23.5 56.5T760-120H200Zm188-212-56-56 372-372H560v-80h280v280h-80v-144L388-332Z" />
        </svg>
        </a>
        }
        <div class="pill" (click)="logout()" style="cursor: pointer">
          Logout
        </div>
      </div>
    </div>
  </div>
</main>